import { Component } from 'react';
import { ContentTypeEnum } from '../../constants/cms-constants';
import BannerComponent from '../../components/BannerComponent';
import { BlockInstanceProps } from '../Block';
import {
  ComponentWithControlOptions,
  ControlOption,
} from '../../utils/controlOptions/ControlOptionDecorator';
import controlOptionDefinitions from '../../utils/controlOptions/controlOptionDefinitions';
import backgroundColors from '../../constants/background-color';
import fontColors from '../../constants/fontColors';

@ControlOption([
  {
    key: 'backgroundColor',
    definition: controlOptionDefinitions.backgroundColor,
  },
  {
    key: 'fontColor',
    definition: controlOptionDefinitions.fontColor,
  },
  {
    key: 'bound',
    definition: controlOptionDefinitions.bannerBoundTypes,
  },
])
class BannerBlock extends ComponentWithControlOptions<BlockInstanceProps> {
  render() {
    const { block, blockContent } = this.props;
    const { bound } = this.controlOptions;
    const controlOptions = block?.controlOptionsV2;
    const backgroundColor = backgroundColors[controlOptions?.backgroundColor];
    const fontColor = fontColors[controlOptions?.fontColor];

    let mediaItems;
    blockContent.doQuery((content) => {
      mediaItems = content.getAllOfType(ContentTypeEnum.MEDIAITEM, true);
    });

    return (
      <BannerComponent
        mediaItems={mediaItems}
        backgroundColor={backgroundColor}
        bound={bound}
        fontColor={fontColor}
      />
    );
  }
}

export default BannerBlock;
