import { Component } from 'react';
import { css } from '@emotion/react';

import * as Breakpoints from '../../../constants/breakpoints';
import { colorEnum } from '../../../constants/colors';

const styles = css({
  '.toggle-switch': {
    position: 'relative',
    width: '75px',
    display: 'inline-block',
    verticalAlign: 'middle',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    textAlign: 'left',
    '&-checkbox': {
      display: 'none',
    },
    '&-label': {
      display: 'block',
      overflow: 'hidden',
      cursor: 'pointer',
      border: `0 solid ${colorEnum.gray}`,
      borderRadius: '20px',
      margin: '0',
    },
    '&-inner': {
      display: 'block',
      width: '200%',
      marginLeft: '-100%',
      transition: 'margin 0.3s ease-in 0s',
      ':before, :after': {
        display: 'block',
        float: 'left',
        width: '50%',
        height: '34px',
        padding: '0',
        lineHeight: '34px',
        fontSize: '14px',
        color: colorEnum.white,
        fontWeight: 'bold',
        boxSizing: 'border-box',
      },
      '&:before': {
        content: '""',
        paddingLeft: '10px',
        backgroundColor: colorEnum.green,
        color: colorEnum.white,
      },
    },
    '&-disabled': {
      cursor: 'not-allowed',
      opacity: '0.6',
      '&:before': {
        cursor: 'not-allowed',
        opacity: '0.6',
      },
    },
    '&-inner:after': {
      content: '""',
      paddingRight: '10px',
      backgroundColor: colorEnum.gray,
      color: colorEnum.white,
      textAlign: 'right',
    },
    '&-switch': {
      display: 'block',
      width: '24px',
      margin: '5px',
      background: colorEnum.white,
      position: 'absolute',
      top: '0',
      bottom: '0',
      right: '40px',
      border: `0 solid ${colorEnum.gray}`,
      borderRadius: '20px',
      transition: 'all 0.3s ease-in 0s',
    },
    '.toggle-switch-checkbox:checked + .toggle-switch-label': {
      '.toggle-switch-inner': {
        marginLeft: '0',
      },
      '.toggle-switch-switch': {
        right: '0px',
      },
    },
    [Breakpoints.Mobile.mq]: {
      transform: 'scale(0.75)',
    },
    [Breakpoints.TabletUp.mq]: {
      transform: 'scale(0.825)',
    },
    [Breakpoints.DesktopUp.mq]: {
      transform: 'scale(0.9)',
    },
  },
});

type ToggleProps = {
  // eslint-disable-next-line no-restricted-globals
  name: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (...args: boolean[]) => void;
};
class Toggle extends Component<ToggleProps> {
  static defaultProps = {
    disabled: false,
  };

  render() {
    const { name, checked, onChange, disabled, ...other } = this.props;

    return (
      <div css={styles} {...other}>
        <div className="toggle-switch">
          <input
            type="checkbox"
            className="toggle-switch-checkbox"
            name={name}
            id={name}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            disabled={disabled}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="toggle-switch-label" htmlFor={name}>
            <span
              className={
                disabled
                  ? 'toggle-switch-inner toggle-switch-disabled'
                  : 'toggle-switch-inner'
              }
            />
            <span
              className={
                disabled
                  ? 'toggle-switch-switch toggle-switch-disabled'
                  : 'toggle-switch-switch'
              }
            />
          </label>
        </div>
      </div>
    );
  }
}

export default Toggle;
