import {
  IDepdendencyInput,
  IErrorInput,
  IEventInput,
  IRequestInput,
} from '../../constants/reporterServiceInputs';
import Consent from '../../utils/Consent';

export default abstract class Driver {
  protected consent: Consent;

  abstract activate(consent: Consent): void;

  abstract getHeaderString(nonce: string): string;

  abstract getBodyString(): string;

  abstract logError(error: IErrorInput): void;

  abstract trackEvent(event: IEventInput): void;

  abstract trackRequest(request: IRequestInput): void;

  abstract trackDependency(dependencyInput: IDepdendencyInput): void;
}
