import Plausible from 'plausible-tracker';
import Driver from '../../client-server-utils/ClientReporterDrivers/Driver';
import { ConfigBase, ConfigVariable } from '../StaticConfig';
import {
  IErrorInput,
  IRequestInput,
  IEventInput,
  IDepdendencyInput,
} from '../../constants/reporterServiceInputs';

const validHosts = ['novataris.com', 'webtest.novataris.com', 'localhost'];

/* Plausible uses the domain to connect. It should further be defined 
   whether tracking on localhost is allowed or not. 
   This is controlled through a variable in the .env file 
   For documentation see:
   https://plausible-tracker.netlify.app/ 
*/
function generateConfig(trackLocalhost) {
  const host = (
    window.location.hostname.replace('www.', '') || null
  ).toLowerCase();

  if (!host || !validHosts.includes(host)) {
    console.warn(
      'Plausible driver not activated: ',
      host,
      ' is not a valid domain',
    );
    return null;
  }

  if (host.includes('localhost')) {
    if (!trackLocalhost) {
      console.warn(
        'Plausible driver not activated: TrackLocalhost is set to false',
      );
      return null;
    }
    return {
      domain: 'webtest.novataris.com',
      trackLocalhost,
    };
  }

  return {
    domain: host,
    trackLocalhost,
  };
}

export default class ClientPlausibleDriver extends Driver {
  private plausible;

  private trackLocalhost;

  // eslint-disable-next-line no-useless-constructor
  constructor(staticConfig: ConfigBase) {
    super();
    if (staticConfig.isSet(ConfigVariable.PlausibleTrackLocalhost)) {
      this.trackLocalhost =
        staticConfig.get(ConfigVariable.PlausibleTrackLocalhost) === 'true';
    }

    const config = generateConfig(this.trackLocalhost);
    if (config) {
      this.plausible = Plausible(config);
      this.plausible.enableAutoPageviews();
    }

    this.trackEvent = this.trackEvent.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  activate(): void {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getHeaderString(nonce: string): string {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getBodyString(): string {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  logError(error: IErrorInput): void {
    return null;
  }

  trackEvent(event: IEventInput): void {
    if (this.plausible) {
      this.plausible.trackEvent(event.name, { props: event.properties });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  trackRequest(request: IRequestInput): void {
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  trackDependency(dependencyInput: IDepdendencyInput): void {
    return null;
  }
}
