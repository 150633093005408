import { Component } from 'react';

import Toggle from '../atoms/Toggle';
import Caption from '../atoms/Caption';
import Grid2CellUnevenSplit from '../atoms/Grid2CellUnevenSplit';

import { colorEnum } from '../../../constants/colors';
import * as Breakpoints from '../../../constants/breakpoints';

const styles = {
  '.cookie-settings-molecule': {
    [Breakpoints.DesktopUp.mq]: {
      marginBottom: '16px',
    },
    [Breakpoints.TabletDown.mq]: {
      marginBottom: '0px',
    },
    '& .toggle-caption': {
      color: colorEnum.white,
    },
  },
};

type CookieSettingsProps = {
  disabled?: boolean;
  // eslint-disable-next-line no-restricted-globals
  name?: string;
  cookieTypeText?: string;
  value?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...args: any[]) => any;
};

class CookieSettings extends Component<CookieSettingsProps> {
  static defaultProps = {
    disabled: false,
    name: null,
    cookieTypeText: null,
    value: false,
  };

  render() {
    const { disabled, name, cookieTypeText, value, onChange } = this.props;

    return (
      <div css={styles}>
        <div className="cookie-settings-molecule">
          <div className="cookie-settings-molecule-container">
            <Grid2CellUnevenSplit
              leftCellCount={4}
              rightCellCount={7}
              onRenderCell1={() =>
                cookieTypeText && (
                  <Caption
                    className="toggle-caption"
                    type={Caption.types.TITLEPROFILE}
                  >
                    {cookieTypeText}
                  </Caption>
                )
              }
              onRenderCell2={() => (
                <Toggle
                  disabled={disabled}
                  name={name}
                  onChange={onChange}
                  checked={value}
                />
              )}
              snapAtBreakpoint={Breakpoints.Mobile}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CookieSettings;
