// eslint-disable-next-line max-classes-per-file
import * as LanguageConstants from './language';
import * as CmsConstants from './cms-constants';
import * as ContentState from './contentstate';
import { ImgixImage } from '../types/ts/contentful';

// /*
//  ██████╗ ██████╗ ███╗   ██╗████████╗███████╗███╗   ██╗████████╗███████╗██╗   ██╗██╗
// ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔════╝████╗  ██║╚══██╔══╝██╔════╝██║   ██║██║
// ██║     ██║   ██║██╔██╗ ██║   ██║   █████╗  ██╔██╗ ██║   ██║   █████╗  ██║   ██║██║
// ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══╝  ██║╚██╗██║   ██║   ██╔══╝  ██║   ██║██║
// ╚██████╗╚██████╔╝██║ ╚████║   ██║   ███████╗██║ ╚████║   ██║   ██║     ╚██████╔╝███████╗
//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚═╝      ╚═════╝ ╚══════╝

// ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
//    ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
//    ██║      ██║   ██║     ███████╗███████║
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝
// */
export function isCmsEntity(cmsEntity) {
  return (
    typeof cmsEntity === 'object' &&
    'sys' in cmsEntity &&
    'type' in cmsEntity.sys &&
    'id' in cmsEntity.sys &&
    'contentType' in cmsEntity.sys &&
    'sys' in cmsEntity.sys.contentType &&
    'id' in cmsEntity.sys.contentType.sys
  );
}

type cmsEntityTypeSys<T> = {
  type: 'Entry' | 'Link';
  contentType: {
    sys: {
      id: T;
    };
  };
};

export interface CmsRichTextObject {
  data: object;
  content?: CmsRichTextObject[];
  nodeType?: string;
  value?: string;
  marks?: object[];
}

export type CmsMediaImage = {
  sys: {
    type: 'Asset';
  };
  fields?: {
    titl?: string;
    file?: {
      url: string;
      contentType: `image/${string}` | `application/${string}`;
    };
  };
};

export type CmsMediaVideo = {
  sys: {
    type: 'Asset';
  };
  fields?: {
    title?: string;
    file?: {
      url?: string;
      contentType?: `video/${string}`;
    };
  };
};

export type CmsEntryLink = {
  sys: {
    type?: 'Link';
    linkType?: 'Entry';
    id: string;
  };
};

export type CmsEntryOrEntryLink = {
  sys: {
    type?: 'Link' | 'Entry';
    linkType?: 'Entry';
    id: string;
  };
};

export type CmsImage = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.IMAGE>;
  fields?: {
    name?: string;
    altText?: string;
    image?: CmsMediaImage;
    imgixQueryString?: string;
    imgixImage?: string;
  };
};

export type CmsVideo = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.VIDEO>;
  fields?: {
    name?: string;
    poster?: CmsMediaImage;
    video?: CmsMediaVideo[];
  };
};

export type CmsLink = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.LINK>;
  fields?: {
    name?: string;
    image?: CmsImage;
    text?: CmsRichTextObject;
    url?: string;
    pageReference?: CmsEntryOrEntryLink;
    language?: string;
    openInNewWindowOrTab?: boolean;
  };
};

export type CmsControlOption = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.CONTROLOPTION>;
  fields?: {
    fixedOption?: CmsConstants.TypeFixedControlOptionTypes;
    customOption?: boolean;
    name?: string;
    valueCustom?: string;
    valuePosition?: CmsConstants.TypePositionTypes;
    valueColor: string;
  };
};

export type CmsControlOptions = CmsControlOption[];

export type CmsPerson = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.PERSON>;
  fields?: {
    name?: string;
    description?: CmsRichTextObject;
    quote?: CmsRichTextObject;
    email?: string;
    jobTitle?: string;
    phone?: string;
    language?: string;
    image?: CmsImage;
  };
};

export type CmsMediaItem = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.MEDIAITEM>;
  fields?: {
    header?: CmsRichTextObject;
    lightHeader?: CmsRichTextObject;
    bodyText?: CmsRichTextObject;
    link?: CmsLink;
    image?: CmsImage;
    sender?: CmsPerson;
    language?: string;
  };
};

export type CmsSnippet = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.SNIPPET>;
  fields?: {
    name?: string;
    text?: CmsRichTextObject;
    language?: string;
  };
};

export type CmsEvent = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.EVENT>;
  fields?: {
    name?: string;
    title?: string;
    date?: string;
    geoLocation?: string;
    address?: string;
    promoTeaser?: string;
    description?: CmsRichTextObject;
    onlineLocation?: CmsLink;
    promoImage?: CmsImage;
    language?: string;
  };
};
export type CmsMailContent = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.MAILCONTENT>;
  fields?: {
    subject?: string;
    title?: string;
    description?: string;
    articleHeader?: string;
    language?: string;
  };
};

export type CmsBlockContentType =
  | CmsSnippet
  | CmsImage
  | CmsVideo
  | CmsPerson
  | CmsMediaItem
  | CmsLink
  | CmsEntryLink
  | CmsMailContent;

export type CmsBlock = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.BLOCK>;
  fields?: {
    name?: string;
    mainHeader?: CmsRichTextObject;
    lightHeader?: CmsRichTextObject;
    bodyText?: CmsRichTextObject;
    controlOptions?: object;
    content?: CmsBlockContentType[];
    link?: CmsLink;
    type?: CmsConstants.TypeBlockTypes;
    language?: string;
  };
};

export type CmsPage = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.PAGE>;
  fields?: {
    name?: string;
    blocks?: CmsBlock[];
    menuColor?: CmsConstants.TypeMenuColorTypes;
    pageSlug?: string;
    modalSlug?: string;
    presentation?: CmsConstants.TypePagePresentationTypes;
    featuredImage?: CmsImage;
    seoPageTitle?: string;
    seoPageDescription?: string;
    noIndexSeo?: boolean;
    language?: string;
  };
};

export type CmsRoot = {
  sys: cmsEntityTypeSys<typeof CmsConstants.ContentTypeEnum.ROOT>;
  fields?: {
    pages?: CmsPage[];
    menuItems?: CmsPage[];
    footerSmallNavigationLinks?: CmsLink[];
    footerLargeNavigationLinks?: CmsLink[];
    footerFinePrint?: CmsMediaItem[];
    footerContactLink?: CmsLink;
    footerContactHeader?: CmsRichTextObject;
    footerSoMeLink: CmsLink[];
    companyLogoBlack?: CmsImage;
    companyLogoWhite?: CmsImage;
    companyNameBlack?: CmsImage;
    companyNameWhite?: CmsImage;
    cookieConsentMessage?: CmsRichTextObject;
    cookiePolicyVersion?: string;
    cookieAcceptAllButtonLabel?: string;
    cookieAcceptNecessaryButtonLabel?: string;
    cookieSaveSettingsButtonLabel?: string;
    defaultTwitterImage?: CmsImage;
    defaultOpenGraphImage?: CmsImage;
  };
};

// /*
// ██╗███╗   ██╗████████╗███████╗██████╗ ███╗   ██╗ █████╗ ██╗
// ██║████╗  ██║╚══██╔══╝██╔════╝██╔══██╗████╗  ██║██╔══██╗██║
// ██║██╔██╗ ██║   ██║   █████╗  ██████╔╝██╔██╗ ██║███████║██║
// ██║██║╚██╗██║   ██║   ██╔══╝  ██╔══██╗██║╚██╗██║██╔══██║██║
// ██║██║ ╚████║   ██║   ███████╗██║  ██║██║ ╚████║██║  ██║███████╗
// ╚═╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚══════╝

// ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
//    ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
//    ██║      ██║   ██║     ███████╗███████║
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝
// */

export type RouterLocation = {
  pathname?: string;
};

export type Breakpoint = {
  narrowestWidthInPx?: number;
  widestWidthInPx?: number;
  mq: string;
};

export type IsoLanguage = typeof LanguageConstants.LANGUAGES[number]['iso'];

export type UrlLanguage =
  typeof LanguageConstants.LANGUAGES[number]['urlLanguage'];

export type LanguageName = typeof LanguageConstants.LANGUAGES[number]['name'];

export type ContentfulSettings = {
  space: string;
  accessToken: string;
  host: string;
  environment: string;
};

export type Language = {
  key: string;
  iso?: IsoLanguage;
  urlLanguage?: UrlLanguage;
  name?: LanguageName;
};

// // keep this the same in order to reuse contentfuls rich text library
export type RichText = CmsRichTextObject;
export type StringOrRichText = string | RichText;
export type StringOrRichTextOrDomElement = JSX.Element | string | RichText;

// // Recursively define a proptype which can be nested arrays of arbitrary depth:
export type NestedArraysWithElementOrString =
  | JSX.Element
  | string
  | NestedArraysWithElementOrString[];

export type StringOrRichTextOrElementOrNestedArrays =
  | NestedArraysWithElementOrString
  | RichText;

export const isRichText = (obj) =>
  typeof obj === 'object' &&
  obj !== null &&
  'data' in obj &&
  'content' in obj &&
  'nodeType' in obj &&
  obj.nodeType === 'document';

export type ControlOption = {
  name?: string;
  fixedOption?: CmsConstants.TypeFixedControlOptionTypes;
  customOption?: boolean;
  valueColor?: CmsConstants.TypeBackgroundColorTypes;
  valuePosition?: CmsConstants.TypePositionTypes;
  list3GroupsType?: CmsConstants.TypeList3GroupsTypes;
  heroType?: CmsConstants.TypeHeroTypes;
  wellType?: CmsConstants.TypeWellTypes;
  pageTag?: CmsConstants.TypePageTags;
  quoteType?: CmsConstants.TypeQuoteTypes;
  headerTextGroupType?: CmsConstants.TypeHeaderTextGroupTypes;
  callToActionType?: CmsConstants.TypeCallToActionTypes;
  callToActionWithAlignmentType?: CmsConstants.TypeCallToActionWithAlignmentTypes;
  keyFigureGridType?: CmsConstants.TypeKeyFigureGridTypes;
  profileGridType?: CmsConstants.TypeProfileGridTypes;
  profileType?: CmsConstants.TypeProfileTypes;
  valueCustom?: string;
};

export type PageReference = {
  name?: string;
  pageSlug?: string;
  modalSlug?: string;
  language?: LanguageName;
  presentation?: CmsConstants.TypePagePresentationTypes;
};

export type ImageFile = {
  url: string;
  assetName?: string;
  heightInPx?: number;
  widthInPx?: number;
  sizeInBytes?: number;
};

export type Image = {
  name?: string;
  contentfulEntryId?: string;
  imgixImage?: ImgixImage;
  contentfulImage?: ImageFile;
  simpleImage?: ImageFile;
  imgixQueryString?: string;
  alternateText?: string;
};

export type Link = {
  name?: string;
  image?: Image;
  text?: RichText;
  url?: string;
  pageReference?: PageReference;
  linkTo?: string;
  language?: LanguageName;
  openInNewWindowOrTab?: boolean;
};

export type VideoFile = {
  url: string;
  title?: string;
  sizeInBytes?: number;
};

export type Video = {
  name?: string;
  poster?: Image;
  videos?: VideoFile[];
  ariaLabel?: string;
};

export type Person = {
  name?: string;
  description?: RichText;
  quote?: RichText;
  email?: string;
  jobTitle?: string;
  phone?: string;
  image?: Image;
  link?: Link;
  language?: LanguageName;
};

export type MediaItem = {
  name?: string;
  header?: RichText;
  lightHeader?: RichText;
  bodyText?: RichText;
  link?: Link;
  image?: Image;
  sender?: Person;
  backgroundColor?: CmsConstants.TypeBackgroundColorTypes;
  language?: LanguageName;
  openInNewWindowOrTab?: boolean;
};

export type NamedSnippet = {
  name?: string;
  text?: RichText;
  language?: LanguageName;
};

export type Event = {
  name?: string;
  title?: string;
  date?: number;
  geoLocation?: string;
  address?: string;
  teaser?: RichText;
  description?: RichText;
  onlineLocation?: Link;
  image?: Image;
  language: LanguageName;
};

export type MailContent = {
  subject?: string;
  title?: string;
  description?: string;
  articleHeader?: string;
  language?: LanguageName;
};

export type PublishedState =
  | typeof ContentState.PUBLISHED
  | typeof ContentState.DRAFT
  | typeof ContentState.CHANGED
  | typeof ContentState.ARCHIVED;

export type ContentStateType = PublishedState;

export type PublishedStateRemark = {
  message?: string;
  state?: PublishedState;
  stackTrace?: string;
  entryId?: string;
};

export type PublishedStateAggregation = {
  state?: PublishedState;
  aggregatedState?: PublishedState;
  remarks?: PublishedStateRemark[];
};

export type BlockContentEntry = {
  cmsType?: string;
  content?:
    | NamedSnippet
    | Image
    | Video
    | Person
    | MediaItem
    | Link
    | Event
    | MailContent;
};

export type ControlOptions = {
  // eslint-disable-next-line
  [key: string]: any;
};

export type Block = {
  name?: string;
  contentfulEntryId?: string;
  header?: {
    main?: RichText;
    light?: RichText;
  };
  bodyText?: RichText;
  controlOptionsV2: ControlOptions;
  link?: Link;
  type?: string;
  content?: BlockContentEntry[];
  language?: LanguageName;
  state?: PublishedStateAggregation;
};

export type Page = {
  contentfulEntryId?: string;
  name?: string;
  blocks?: Block[];
  menuColor?: CmsConstants.TypeMenuColorTypes;
  slug?: {
    page?: string;
    modal?: string;
  };
  linkTo?: string;
  isModalPage?: boolean;
  isSystemPage?: boolean;
  presentation?: CmsConstants.TypePagePresentationTypes;
  featuredImage?: Image;
  defaultTwitterImage?: Image;
  controlOptionsV2: ControlOptions;
  defaultOpenGraphImage?: Image;
  state?: PublishedStateAggregation;
  seo?: {
    title?: string;
    description?: string;
    noIndex?: boolean;
  };
  language?: LanguageName;
  alternatePages?: PageReference[];
};

export type Footer = {
  contact?: {
    header?: RichText;
    link?: Link;
  };
  finePrint?: MediaItem[];
  navigationLinks?: {
    small?: Link[];
    large?: Link[];
  };
  soMe?: Link[];
};

export type Root = {
  images?: {
    logo?: {
      white?: Image;
      black?: Image;
    };
    name?: {
      white?: Image;
      black?: Image;
    };
  };
  footer?: Footer;
  pages?: Page[];
  modalPages?: Page[];
  systemPages?: Page[];
  menuItems?: Page[];
  cookie?: {
    message?: RichText;
    policyVersion?: string;
    acceptAllButtonLabel?: string;
    acceptNecessaryButtonLabel?: string;
    saveSettingsButtonLabel?: string;
  };
};

export type AppSettings = {
  editorToolsEnabled?: boolean;
  language?: {
    current?: Language;
    currentIso?: string;
  };
  contentful?: {
    isPreview?: boolean;
    spaceId?: string;
    environments?: string[];
    environment?: string;
    current?: {
      content?: {
        space?: string;
        accessToken?: string;
      };
      preview?: {
        space?: string;
        accessToken?: string;
      };
    };
  };
  consent?: string;
};

export type hexCode = `#${string}`;
