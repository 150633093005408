import { Page } from '../constants/internal-types';

import CookieService from './CookieService';
import ReporterService from './ReporterService';
import { ConfigBase, ConfigVariable } from './StaticConfig';

import Consent from '../utils/Consent';

import SelfConnector from './connectors/self';
import ContentfulConnector from './connectors/contentful';
import AppSettings from './AppSettings';

type AppConfigEventHandlersType = {
  onChangeLanguage: (lng: string) => void;
  onChangeContentfulEnvironment: (env: string) => void;
  onTogglePreview: () => void;
  onCookieConsentGiven: (consent?: Consent) => void;
};

type relatedPagesInfoType = {
  allPages: Page[];
  currentPageId: string;
};

export type AppConfigConnectorsType = {
  self: SelfConnector;
  contentful: ContentfulConnector;
};

export type AppConfigConnectors = AppConfigConnectorsType;

export type AppConfigServicesType = {
  cookieService: CookieService;
  reporterService: ReporterService;
};

export type AppConfigType = {
  static: { buildVersion: string };
  settings: AppSettings;
  eventHandlers: AppConfigEventHandlersType;
  services: AppConfigServicesType;
  connectors: AppConfigConnectorsType;
  currentPageAlternates?: Page;
  imageImgixPrefix?: string;
  azureStorageAccount: string;
  relatedPagesInfo: relatedPagesInfoType;
};

export function createAppConfig(
  staticConfig: ConfigBase,
  settings: AppSettings,
  currentPageAlternates: Page,
  services: AppConfigServicesType,
  eventHandlers: AppConfigEventHandlersType,
  relatedPagesInfo,
): AppConfigType {
  const { reporterService } = services;
  // Check if any subscriptions exist, if so unsubscribe and delete
  reporterService.resetSubscriptions();

  const connectors = {
    self: new SelfConnector(),
    contentful: new ContentfulConnector(settings),
  };

  reporterService.createSubscriptions(connectors);

  return {
    static: {
      buildVersion: staticConfig.get(ConfigVariable.BuildVersion),
    },
    settings,
    currentPageAlternates,
    eventHandlers,
    services,
    connectors,
    imageImgixPrefix: staticConfig.get(ConfigVariable.ImageImgixPrefix),
    azureStorageAccount: staticConfig.get(ConfigVariable.AzureStorageAccount),
    relatedPagesInfo,
  };
}
