import { ReactNode, Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Breakpoints from '../../../constants/breakpoints';
import {
  calculateEvenGridColumnClassNames,
  calculateEvenGridColumnsSizes,
} from '../../../utils/gridBreakpointCalculations';

type GridEvenSplitProps = {
  className?: string;
  onRenderCells: ((...args: any[]) => ReactNode)[];
  snapAtBreakpoint?: Breakpoints.BreakpointType;
  twoColumnLayout?: boolean;
};

class GridEvenSplit extends Component<GridEvenSplitProps> {
  static defaultProps = {
    snapAtBreakpoint: Breakpoints.Mobile,
    className: undefined,
    twoColumnLayout: true,
  };

  render() {
    const { onRenderCells, snapAtBreakpoint, className, twoColumnLayout } =
      this.props || {};

    const columns = calculateEvenGridColumnsSizes(
      snapAtBreakpoint,
      onRenderCells.length,
      twoColumnLayout,
    );
    const columnClassNames = calculateEvenGridColumnClassNames(
      onRenderCells.length,
    );

    return (
      <Container className={className} fluid>
        <Row>
          {onRenderCells.map((cell, index) => (
            <Col
              {...columns}
              className={columnClassNames[index]}
              key={columnClassNames[index]}
            >
              {cell()}
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default GridEvenSplit;
