/* eslint-disable no-unused-expressions */
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Spinner from 'react-bootstrap/Spinner';
import Helmet from 'react-helmet';
import * as InternalPropTypes from '../constants/internal-types';
import Block from './Block';
import ContentfulPropMapper from '../utils/ContentfulPropMapper';
import withAppConfig, { WithAppConfigProps } from '../utils/hocs/withAppConfig';
import withLoadingContext, {
  WithLoadingContextProps,
} from '../utils/hocs/withLoadingContext';
import { PAGE_KEY } from '../constants/cachekeys';
import * as seoUtil from '../utils/seo';
import {
  changeHashToQuery,
  getBasepathFromUrl,
  getBasepathWithLanguageFromUrl,
  getPageUrlFromPageReference,
} from '../utils/url';
import PagePublishedState from '../components/common/molecules/PagePublishedState';
import ContentfulLoadingComponent, {
  ContentfulLoadingComponentProps,
  ContentfulLoadingComponentState,
} from './ContentfulLoadingComponent';
import { EntriesQueries } from '../client-server-utils/connectors/contentful';
import { TypePage } from '../types/ts/contentful';
import withImageHelperFactory, {
  WithImageHelperFactoryProps,
} from '../utils/hocs/withImageHelperFactory';
import { cmsImageOfMorten } from '../stories/StoryUtils/StoryExampleElements';
import { AppConfigType } from '../client-server-utils/appConfig';

type PageComponentInnerProps = ContentfulLoadingComponentProps &
  WithImageHelperFactoryProps &
  RouteComponentProps &
  WithAppConfigProps &
  WithLoadingContextProps & {
    className: string | null;
    page: InternalPropTypes.Page;
  };

type PageComponentProps = {
  page: InternalPropTypes.Page;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type PageComponentState = ContentfulLoadingComponentState & {};

export function isPageCacheKey(cacheKey) {
  return typeof cacheKey === 'string' && cacheKey.startsWith(PAGE_KEY);
}

export class PageComponent extends ContentfulLoadingComponent<
  PageComponentInnerProps,
  PageComponentState
> {
  static defaultProps = {
    className: null,
  };

  loadContent(): Promise<object> {
    const { appConfig } = this.props;
    const { settings, connectors } = appConfig;
    const propPage = this.props.page;
    return new Promise((resolve, reject) => {
      const connector = connectors.contentful;
      Promise.all([
        connector.call(
          EntriesQueries.getPageAndBlocks,
          propPage?.contentfulEntryId,
        ),
        connector.call(
          EntriesQueries.getPageInAllLanguages,
          propPage?.contentfulEntryId,
        ),
      ])
        .then(([getPageAndBlocksResponse, getPageInAllLanguagesResponse]) => {
          const pageResponse = getPageAndBlocksResponse.isPreview
            ? getPageAndBlocksResponse.preview
            : getPageAndBlocksResponse.content;

          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          const cmsPage = (pageResponse as any).items[0];
          const cmsContentPage = getPageAndBlocksResponse.content.items[0];
          const mapper = new ContentfulPropMapper(appConfig);
          /*
        // Event page stuff

        PropTypes.checkPropTypes(
          { eventPage: InternalPropTypes.CmsEventPage },
          { eventPage: data.mappedFirstItem.currentContent },
          'state.cmsContent.current',
          'Event Page',
        );

        return mapper.mapEventPage(
          data.mappedFirstItem.currentContent,
          this.props.appConfig.settings.language.currentIso,
          {
            twitter: this.props.page?.defaultTwitterImage,
            openGraph: this.props.page?.defaultOpenGraphImage,
          },
          data.mappedFirstItem.publishedContent,
        );
        */

          resolve(
            mapper.mapPage(
              cmsPage,
              settings.language.currentIso,
              {
                twitter: this.props.page?.defaultTwitterImage,
                openGraph: this.props.page?.defaultOpenGraphImage,
              },
              cmsContentPage as TypePage,
              getPageInAllLanguagesResponse,
            ),
          );
        })
        .catch(reject);
    });
  }

  getCanonicalUrl(mappedPage: InternalPropTypes.Page) {
    const { loadingManager, location } = this.props;
    const url = loadingManager.getCurrentUrl();
    const isModalPage = mappedPage?.isModalPage;
    const modalSlug = mappedPage?.slug?.modal;
    const formattedModalSlug = changeHashToQuery(modalSlug);
    const modalBaseUrl = getBasepathWithLanguageFromUrl(url) ?? url;
    const modalMinimalUrl = `${modalBaseUrl}${formattedModalSlug}`;

    return (isModalPage ? modalMinimalUrl : url).toLowerCase();
  }

  _getSeo(mappedPage: InternalPropTypes.Page) {
    const twitterImageUrl = seoUtil.getTwitterImageUrl(
      mappedPage,
      this.props.imageHelperFactory,
    );
    const ogImage = seoUtil.getOpenGraphImage(mappedPage);
    const title = mappedPage?.seo?.title || 'Novataris';
    const description = mappedPage?.seo?.description;
    const canonicalUrl = this.getCanonicalUrl(mappedPage);
    const language = this.props.appConfig.settings.language.currentIso;
    return (
      <Helmet title={title}>
        <html lang={language} />
        <meta name="title" content={title} />
        <meta name="description" content={description || ''} />
        <meta
          name="robots"
          content={mappedPage?.seo?.noIndex ? 'noindex' : 'all'}
        />
        <link rel="canonical" href={canonicalUrl} />
        {this._getOpenGraphMetaTags(
          title,
          canonicalUrl,
          ogImage,
          description,
          language,
        )}
        {this._getTwitterMetaTags(title, twitterImageUrl, description || '')}
        {this._getAlternateLinkTags(mappedPage?.alternatePages, canonicalUrl)}
      </Helmet>
    );
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  _getOpenGraphMetaTags(
    title,
    url,
    image: InternalPropTypes.Image,
    description,
    language,
  ) {
    const { imageHelperFactory } = this.props;

    const imageUrl = imageHelperFactory.createImageHelper(image).getImageUrl();
    const { widthInPx, heightInPx } = seoUtil.getImageMetaData(
      image,
      imageHelperFactory,
    );

    const tags = [
      <meta property="og:site_name" content="Novataris" key="og:site_name" />,
      <meta property="og:type" content="website" key="og:type" />,
    ];
    title &&
      tags.push(<meta property="og:title" content={title} key="og:title" />);
    description &&
      tags.push(
        <meta
          property="og:description"
          content={description}
          key="og:description"
        />,
      );
    imageUrl &&
      tags.push(
        <meta property="og:image" content={imageUrl} key="og:image" />,
        <meta
          property="og:image:secure_url"
          content={`https:${imageUrl}`}
          key="og:image:secure_url"
        />,
      );
    heightInPx &&
      tags.push(
        <meta
          property="og:image:height"
          content={`${heightInPx}`}
          key="og:image:height"
        />,
      );
    widthInPx &&
      tags.push(
        <meta
          property="og:image:width"
          content={`${widthInPx}`}
          key="og:image:width"
        />,
      );
    language &&
      tags.push(
        <meta property="og:locale" content={language} key="og:locale" />,
      );
    return tags;
  }

  // eslint-disable-next-line class-methods-use-this
  _getTwitterMetaTags(title: string, imgSrc: string, description: string) {
    const tags = [
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />,
    ];
    title &&
      tags.push(
        <meta name="twitter:title" content={title} key="twitter:title" />,
      );
    description &&
      tags.push(
        <meta
          name="twitter:description"
          content={description}
          key="twitter:description"
        />,
      );
    imgSrc &&
      tags.push(
        <meta name="twitter:image" content={imgSrc} key="twitter:image" />,
      );
    return tags;
  }

  // eslint-disable-next-line class-methods-use-this
  _getAlternateLinkTags(alternatePages, currentUrl) {
    return alternatePages.map((pageRef) => {
      if (pageRef == null) return null;
      const fullyQualifiedPageUrl =
        getBasepathFromUrl(currentUrl) + getPageUrlFromPageReference(pageRef);
      return (
        <link
          rel="alternate"
          href={fullyQualifiedPageUrl}
          hrefLang={pageRef.language}
          key={pageRef.language}
        />
      );
    });
  }

  getTypeAndEntryId() {
    return `${PAGE_KEY}-${this.props.page?.contentfulEntryId}`;
  }

  // eslint-disable-next-line class-methods-use-this
  renderUnloaded() {
    return (
      <div
        style={{
          textAlign: 'center',
          paddingTop: '60px',
          paddingBottom: '20px',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner animation="grow" role="status">
          <span className="sr-only">Loading page</span>
        </Spinner>
      </div>
    );
  }

  renderLoaded(ownPage) {
    const { className, appConfig } = this.props;
    if (!ownPage) {
      return null;
    }
    // eslint-disable-next-line no-param-reassign
    ownPage = ownPage as InternalPropTypes.Page;

    appConfig.relatedPagesInfo.currentPageId = ownPage.contentfulEntryId;

    const showPublishedState = appConfig.settings.contentful.isPreview;
    return (
      <div
        key={ownPage.contentfulEntryId}
        className={`page${className ? ` ${className}` : ''}`}
      >
        {this._getSeo(ownPage)}
        {showPublishedState && <PagePublishedState page={ownPage} />}
        {ownPage?.blocks
          ? ownPage.blocks.map((block, idx) => (
              <Block
                /* eslint-disable-next-line react/no-array-index-key */
                key={`${block?.contentfulEntryId}-block-${idx}`}
                block={block}
              />
            ))
          : null}
      </div>
    );
  }
}

export default compose<PageComponentInnerProps, PageComponentProps>(
  withImageHelperFactory,
  withAppConfig,
  withRouter,
  withLoadingContext,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
)(PageComponent as any);
