import { Component, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as Breakpoints from '../../../constants/breakpoints';

function getRenderedItems(cellRenderers) {
  const renderedItems = [];

  cellRenderers.forEach((renderer, index) => {
    // eslint-disable-next-line react/no-array-index-key
    renderedItems.push(<Col key={`h-grid-item-${index}`}>{renderer()}</Col>);
  });
  return renderedItems;
}

type GridHorizontalSnap2Props = {
  onRenderCells?: ((...args: any[]) => ReactNode)[];
  snapAtBreakpoint?: Breakpoints.BreakpointType;
  className?: string;
};

class GridHorizontalSnap2 extends Component<GridHorizontalSnap2Props> {
  static defaultProps = {
    onRenderCells: [() => null],
    snapAtBreakpoint: null,
    className: 'snap-grid',
  };

  render() {
    const { onRenderCells, snapAtBreakpoint, className } = this.props;
    const rowElementNumber = Math.min(onRenderCells.length, 6);

    return (
      <Container className={`grid-h-snap ${className}`} fluid>
        <Row xs={2} md={rowElementNumber}>
          {getRenderedItems(onRenderCells)}
        </Row>
      </Container>
    );
  }
}

export default GridHorizontalSnap2;
