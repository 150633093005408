import { Subscription } from 'rxjs';
import Consent from '../utils/Consent';
import Driver from './ClientReporterDrivers/Driver';
import {
  IDepdendencyInput,
  IErrorInput,
  IEventInput,
  IRequestInput,
} from '../constants/reporterServiceInputs';
import ErrorInput from '../utils/reporterInput/ErrorInput';
import DependencyInput from '../utils/reporterInput/DependencyInput';
import SelfConnector from './connectors/self';

export default class ReporterService {
  private consent: Consent;

  private subscription: Subscription[];

  private drivers: Driver[];

  constructor(drivers: Driver[]) {
    this.drivers = drivers;
    this.consent = null;
    this.subscription = [];

    this.trackConnectorCall = this.trackConnectorCall.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.createSubscriptions = this.createSubscriptions.bind(this);
    this.resetSubscriptions = this.resetSubscriptions.bind(this);
  }

  createSubscriptions(connectors: object) {
    Object.values(connectors).forEach((connector) => {
      this.subscription.push(
        connector.subscribeToFetches(this.trackConnectorCall),
      );
      if (connector instanceof SelfConnector) {
        this.subscription.push(connector.submitToEvent(this.trackEvent));
      }
    });
  }

  resetSubscriptions() {
    this.subscription.forEach((subscription) => subscription.unsubscribe());
    this.subscription = [];
  }

  trackConnectorCall(dependencyInput: IDepdendencyInput | IErrorInput) {
    if (dependencyInput instanceof DependencyInput) {
      this.drivers.forEach((driver) => {
        driver.trackDependency(dependencyInput);
      });
    } else {
      this.reportError(dependencyInput as ErrorInput);
    }
  }

  activate(consent: Consent) {
    this.consent = consent;
    this.activateDrivers();
  }

  activateDrivers() {
    this.drivers.forEach((driver) => driver.activate(this.consent));
  }

  getHeaderString(nonce: string) {
    const headerString = this.drivers
      .map((driver) => driver.getHeaderString(nonce))
      .join('\n');
    return headerString;
  }

  getBodyString() {
    const bodyString = this.drivers
      .map((driver) => driver.getBodyString())
      .join('\n');
    return bodyString;
  }

  reportError(error: IErrorInput) {
    this.drivers.forEach((driver) => {
      driver.logError(error);
    });
  }

  trackEvent(event: IEventInput) {
    this.drivers.forEach((driver) => {
      driver.trackEvent(event);
    });
  }

  trackRequest(request: IRequestInput) {
    this.drivers.forEach((driver) => {
      driver.trackRequest(request);
    });
  }
}
