/* eslint-disable react/prop-types */
import { Component } from 'react';
import { css } from '@emotion/react';
import { compose } from 'recompose';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import CookieSettingsMolecule from './common/molecules/CookieSettings';
import BodyText from './common/atoms/BodyText';
import withAppConfig, { WithAppConfigProps } from '../utils/hocs/withAppConfig';
import * as CookieConstants from '../client-server-utils/Cookie';
import HstackSpacing from './common/atoms/HstackSpacing';
import RichTextWrapper from './common/RichTextWrapper';

import Consent from '../utils/Consent';
import RichTextRenderer from '../utils/content/RichTextRenderer';

import * as InternalPropTypes from '../constants/internal-types';
import { colorEnum } from '../constants/colors';
import * as Breakpoints from '../constants/breakpoints';
import { CONSENTCATEGORIES, consentEnum } from '../constants/consentCategories';
import {
  BackgroundColorEnum,
  TypeBackgroundColorTypes,
} from '../constants/cms-constants';

const styles = css({
  backgroundColor: BackgroundColorEnum.TRANSPARENT,
  paddingTop: '10px',
  paddingBottom: '10px',
  '.cookie-settings-component': {
    '& .text-container': {
      color: colorEnum.white,
      [Breakpoints.DesktopUp.mq]: {
        marginBottom: '30px',
      },
      [Breakpoints.TabletDown.mq]: {
        marginBottom: '10px',
      },
    },
    '& .cookie-categories': {
      marginBottom: '10px',
    },
    '& .submit-button': {
      padding: '10px 20px',
      borderRadius: '10px',
      whiteSpace: 'nowrap',
      [Breakpoints.TabletUp.mq]: {
        fontSize: '20px',
        margin: '5px 50px 5px 50px',
      },
      [Breakpoints.MobileDown.mq]: {
        fontSize: '15px',
        margin: '5px 20px 5px 0px',
      },
      '&.accept-button': {
        backgroundColor: colorEnum.green,
        p: {
          color: colorEnum.white,
        },
      },
      '&.save-button': {
        backgroundColor: colorEnum.white,
      },
    },
  },
});

type CookieSettingsProps = {
  consentMessage?: InternalPropTypes.RichText;
  cookiePolicyVersion?: string;
  acceptAllButtonText?: string;
  acceptNecessaryButtonText?: string;
  saveSettingsButtonText?: string;
  backgroundColor?: TypeBackgroundColorTypes;
  language?: string;
};

type CookieSettingsInnerProps = WithAppConfigProps & CookieSettingsProps;
interface ICookieSettingsState {
  consent: Consent;
}

class CookieSettings extends Component<
  CookieSettingsInnerProps,
  ICookieSettingsState
> {
  static defaultProps = {
    consentMessage: null,
    cookiePolicyVersion: null,
    acceptAllButtonText: null,
    acceptNecessaryButtonText: null,
    saveSettingsButtonText: null,
    backgroundColor: null,
    language: null,
  };

  constructor(props) {
    super(props);
    this.state = { consent: new Consent('all', props.cookiePolicyVersion) };

    this.setHandleChange = this.setHandleChange.bind(this);
    this.handleSubmitAcceptAll = this.handleSubmitAcceptAll.bind(this);
    this.handleSubmitAcceptNecessary =
      this.handleSubmitAcceptNecessary.bind(this);
    this.handleSubmitSave = this.handleSubmitSave.bind(this);
  }

  handleSubmitAcceptAll() {
    const consent = new Consent('all', this.props.cookiePolicyVersion);
    this.props.appConfig.eventHandlers.onCookieConsentGiven(consent);
  }

  handleSubmitAcceptNecessary() {
    const consent = new Consent('necessary', this.props.cookiePolicyVersion);
    this.props.appConfig.eventHandlers.onCookieConsentGiven(consent);
  }

  handleSubmitSave() {
    this.props.appConfig.eventHandlers.onCookieConsentGiven(this.state.consent);
  }

  setHandleChange(key) {
    return (e) => {
      this.setState((prevState) => ({
        consent: prevState.consent.updateCookieConsent(key, e),
      }));
    };
  }

  render() {
    const {
      consentMessage,
      acceptAllButtonText,
      acceptNecessaryButtonText,
      saveSettingsButtonText,
      backgroundColor,
      language,
    } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div css={[styles, addStyle]}>
        <div className="cookie-settings-component">
          <ContentOuterBound>
            <ContentSpacer>
              <div>
                <div className="text-container">
                  <RichTextWrapper
                    richText={consentMessage}
                    renderFunction={
                      RichTextRenderer.textHeadersLinksAndListsInDiv
                    }
                  />
                </div>
                <div className="cookie-categories">
                  {CONSENTCATEGORIES.map((item) => (
                    <div key={item.key}>
                      <CookieSettingsMolecule
                        disabled={item.key === consentEnum.NECESSARY}
                        name={item.key}
                        cookieTypeText={item.text[language]}
                        value={this.state.consent[item.key]}
                        onChange={this.setHandleChange(item.key)}
                      />
                    </div>
                  ))}
                </div>
                <div>
                  <HstackSpacing spacing={HstackSpacing.CENTER}>
                    <button
                      type="submit"
                      className="submit-button accept-button"
                      onClick={this.handleSubmitAcceptAll}
                    >
                      {acceptAllButtonText && (
                        <BodyText className="accept-all-text">
                          {acceptAllButtonText}
                        </BodyText>
                      )}
                    </button>
                    <button
                      type="submit"
                      className="submit-button accept-button"
                      onClick={this.handleSubmitAcceptNecessary}
                    >
                      {acceptNecessaryButtonText && (
                        <BodyText className="accept-necessary-text">
                          {acceptNecessaryButtonText}
                        </BodyText>
                      )}
                    </button>
                    <button
                      type="submit"
                      className="submit-button save-button"
                      onClick={this.handleSubmitSave}
                    >
                      {saveSettingsButtonText && (
                        <BodyText className="save-text">
                          {saveSettingsButtonText}
                        </BodyText>
                      )}
                    </button>
                  </HstackSpacing>
                </div>
              </div>
            </ContentSpacer>
          </ContentOuterBound>
        </div>
      </div>
    );
  }
}

export default compose<CookieSettingsInnerProps, CookieSettingsProps>(
  withAppConfig,
)(CookieSettings);
